import {useSettings} from '@wix/tpa-settings/react'
import classNames from 'classnames'
import React from 'react'
import {ExpandArrow} from '../../../../../../../../icons/ExpandArrow'
import settingsParams from '../../../../../../settingsParams'
import {useVisibilityStyles} from '../../../../../hooks/use-visibility-styles'
import s from './additional-info-toggle.scss'

export const AdditionalInfoToggle = ({opened, active}: {opened: boolean; active: boolean}) => {
  const {get} = useSettings()
  const {allBreakpoints} = useVisibilityStyles()

  if (!allBreakpoints.isListAdditionalComponentsVisible()) {
    return null
  }

  const classes = classNames(s.root, s.linkFont, {
    [s.activeColor]: active,
  })

  const text = opened ? get(settingsParams.lessInfo) : get(settingsParams.moreInfo)

  return (
    <div className={classes} role="button" aria-expanded={opened}>
      {text}
      <span className={classNames(s.icon, {[s.reversedIcon]: opened})}>
        <ExpandArrow size={14} />
      </span>
    </div>
  )
}
