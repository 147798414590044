import {useStyles} from '@wix/tpa-settings/react'
import {WIDGET_TYPE} from '@wix/wix-events-commons-statics'
import React from 'react'
import stylesParams from '../../../../stylesParams'
import {FeaturedMobileCard} from './featured-mobile-card'
import {MobileCardsList} from './mobile-cards-list'

export const CardsMobile = () => {
  const {get} = useStyles()
  const featuredWidget = get(stylesParams.widgetType) === WIDGET_TYPE.FEATURED

  return featuredWidget ? <FeaturedMobileCard /> : <MobileCardsList />
}
