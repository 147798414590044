import {useStyles} from '@wix/tpa-settings/react'
import {ExperimentNames, MOBILE_WIDGET_LAYOUT} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {useEffect} from 'react'
import {isMobile} from '../../../../../../../commons/selectors/environment'
import stylesParams from '../../../../../stylesParams'
import {useWidgetState} from '../../../../hooks/state-provider'
import {useEventsList} from '../../../../hooks/use-events-list'
import {getComponentConfig, isListLayout} from '../../../../selectors/settings'
import {focusFirstNewEvent} from '../../../../utils/a11y'
import {CardContainer} from '../../../event-container/card-container'
import {ListItemContainer} from '../../../event-container/list-item-container'
import {LoadMoreButton} from '../../../load-more-button'
import {MainListTitle} from '../../../main-list-title'
import {CardsMobileItem} from '../cards-mobile-item'
import s from './mobile-cards-list.scss'

export const MobileCardsList = () => {
  const {events, hasMore, moreLoading, pageLimit} = useEventsList()
  const {get} = useStyles()
  const {experiments} = useExperiments()

  const mobile = useWidgetState(isMobile)
  const componentConfig = useWidgetState(getComponentConfig)
  const isDesktopListLayout = isListLayout(componentConfig)
  const widgetPaddingSettingsEnabled = experiments.enabled(ExperimentNames.WidgetPaddingSettings)

  const className = classNames(s.container, {
    [s.border]: isDesktopListLayout || experiments.enabled(ExperimentNames.MobileSettingsPanelRevamp),
    [s.noBottomPadding]: !widgetPaddingSettingsEnabled && isDesktopListLayout,
    [s.narrow]: !mobile,
    [s.overridePadding]: widgetPaddingSettingsEnabled,
  })

  const Container = (
    experiments.enabled(ExperimentNames.ListCardsLayoutType)
      ? get(stylesParams.mobileWidgetLayout) !== MOBILE_WIDGET_LAYOUT.GRID
      : isDesktopListLayout
  )
    ? ListItemContainer
    : CardContainer

  const eventsCount = events.length

  useEffect(() => {
    focusFirstNewEvent({eventsCount, pageLimit, hasMore, compId: componentConfig.id})
  }, [eventsCount])

  return (
    <div className={className} data-hook="ev-list-layout">
      <MainListTitle />
      <ul>
        {events.map((event: ExtendedEvent, index: number) => (
          <Container
            mobile
            applyPadding={!widgetPaddingSettingsEnabled}
            key={event.id}
            event={event}
            showTopDivider={widgetPaddingSettingsEnabled && index === 0}
            hideDivider={!widgetPaddingSettingsEnabled && events.length - 1 === index && !hasMore}
            lastItem={events.length - 1 === index}
          >
            {hovered => <CardsMobileItem event={event} hovered={hovered} />}
          </Container>
        ))}
      </ul>
      <div
        className={
          !experiments.enabled(ExperimentNames.CornerRadiusAndShadow) &&
          (!widgetPaddingSettingsEnabled || hasMore || moreLoading)
            ? s.loadMoreWrapper
            : null
        }
      >
        <LoadMoreButton mobile hasMore={hasMore} moreLoading={moreLoading} />
      </div>
    </div>
  )
}
