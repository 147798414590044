import {useStyles} from '@wix/tpa-settings/react'
import {ExperimentNames, LIST_LAYOUT, MOBILE_WIDGET_LAYOUT} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import React from 'react'
import stylesParams from '../../../../../stylesParams'
import {useWidgetState} from '../../../../hooks/state-provider'
import {getEvents} from '../../../../selectors/events'
import {CardContainer} from '../../../event-container/card-container'
import {ListItemContainer} from '../../../event-container/list-item-container'
import {CardsMobileItem} from '../cards-mobile-item'
import s from './featured-mobile-card.scss'

export const FeaturedMobileCard = () => {
  const [featuredEvent] = useWidgetState(getEvents)
  const {experiments} = useExperiments()
  const {get} = useStyles()
  const isDesktopListLayout = get(stylesParams.listLayout) === LIST_LAYOUT.LIST

  const Container = (
    experiments.enabled(ExperimentNames.ListCardsLayoutType)
      ? get(stylesParams.mobileWidgetLayout) !== MOBILE_WIDGET_LAYOUT.GRID
      : isDesktopListLayout
  )
    ? ListItemContainer
    : CardContainer

  return (
    <div className={s.alignment}>
      <Container
        mobile
        lastItem
        applyPadding={!experiments.enabled(ExperimentNames.WidgetPaddingSettings)}
        showTopDivider
        event={featuredEvent}
      >
        {hovered => <CardsMobileItem event={featuredEvent} hovered={hovered} />}
      </Container>
    </div>
  )
}
