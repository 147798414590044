import React from 'react'
import {LinkToPage} from '../../../../link-to-page'
import s from './title.scss'

interface TitleProps {
  event: wix.events.Event
}

export const Title = ({event}: TitleProps) => (
  <span className={s.title} data-hook="ev-list-item-title">
    <LinkToPage event={event} allowExternal>
      {event.title}
    </LinkToPage>
  </span>
)
