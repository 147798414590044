import {hasImage} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import React from 'react'
import {EventImage} from '../../../event-image'
import s from './image.scss'

export interface ImageProps {
  event: wix.events.Event
  containerWidth?: number | string
  squareImage?: boolean
}

export const Image = ({event}: ImageProps) => (
  <div data-hook={hasImage(event) ? DH.listImage : DH.listImagePlaceholder} className={s.image}>
    <EventImage event={event} backgroundFallback />
  </div>
)
