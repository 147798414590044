import {isDateTbd} from '@wix/wix-events-commons-statics'
import React from 'react'
import {useEventDateInformation} from '../../../../../../../../commons/hooks/dates'
import {useVisibilityStyles} from '../../../../../hooks/use-visibility-styles'
import s from './date.scss'
import {DateProps} from './interfaces'

export const Date = ({event}: DateProps) => {
  const {
    allBreakpoints: {isListDateVisible},
  } = useVisibilityStyles()
  const {shortStartDate} = useEventDateInformation(event.id)

  const dateTbd = isDateTbd(event)

  return isListDateVisible() ? (
    <div className={s.date} data-hook={dateTbd ? 'ev-date-tbd' : 'ev-date'}>
      {shortStartDate}
    </div>
  ) : null
}
